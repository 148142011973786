import React from 'react';

import { useSelector } from 'react-redux';

import Error from './Modal.Error';
import Rules from './Modal.Rules';
import Policy from './Modal.Policy';
import History from './Modal.History';
import Confirm from './Modal.Confirm';
import Congrat from './Modal.Congrat';
import CongratBig from './Modal.CongratBig';

const Modal = () => {
  const state = useSelector((state) => state);

  if (!state.main.info) {
    return (
      <>
        <Error {...state} />
      </>
    );
  }

  return (
    <>
      <Error {...state} />
      <Rules {...state} />
      <Policy {...state} />
      <History {...state} />
      <Confirm {...state} />
      <Congrat {...state} />
      <CongratBig {...state} />
    </>
  );
};

export default Modal;
