import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';

import auth from './auth';
import main from './main';
import throttle from './throttle';
import modal from './modal';

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: [''],
};

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  blacklist: ['err_code'],
};

const mainPersistConfig = {
  key: 'main',
  storage: storage,
  whitelist: [''],
};

const throttlePersistConfig = {
  key: 'throttle',
  storage: storage,
  blacklist: [''],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  main: persistReducer(mainPersistConfig, main),
  throttle: persistReducer(throttlePersistConfig, throttle),
  modal,
});

export default persistReducer(rootPersistConfig, rootReducer);
