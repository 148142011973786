export const CDN = 'https://cdngarenanow-a.akamaihd.net/gstaticid/FF/luchalibre/';

export const preload_data = [
  // IMG

  // CDN + 'ico_dart_on_board.png',
  'https://cdngarenanow-a.akamaihd.net/webid/FF_hash/luchalibre/images-hash/6935371d0c5791b168b19d517e18fa04a1909d9a7602c24e59576a47381fffd5.png',

  // CDN + 'ico_sound_off.png',
  'https://cdngarenanow-a.akamaihd.net/webid/FF_hash/luchalibre/images-hash/09d7a6fa751f235fd725859a63904063b02a06c6ee5f4b9fa491fc4f1dba2c48.png',

  // CDN + 'tooltip.png',
  'https://cdngarenanow-a.akamaihd.net/webid/FF_hash/luchalibre/images-hash/1fb69198672938189e19da8053e154ae70dbc46e9433c57ef97dbf9143a00e7a.png',

  // CDN + 'tick_blue.png',
  'https://cdngarenanow-a.akamaihd.net/webid/FF_hash/luchalibre/images-hash/23ca075e852b4c28019c4cc445254a53a94fa00f2286d0ecb5ff32f36bfeaa05.png',

  // CDN + 'tick_yellow.png',
  'https://cdngarenanow-a.akamaihd.net/webid/FF_hash/luchalibre/images-hash/f8ab6ec08379e972773bf92a3c04726a8046d389958ffcf4ca87ed7921b00e31.png',

  // CDN + 'bg_popup.png',
  'https://cdngarenanow-a.akamaihd.net/webid/FF_hash/luchalibre/images-hash/08fe82a6c1e7efab5a093bb62d7fc99646429fa6d99fb5fd753d6547d91cc0e4.png',

  // CDN + 'bg_popup_grand.png',
  'https://cdngarenanow-a.akamaihd.net/webid/FF_hash/luchalibre/images-hash/add96832d2189d6cf75e8901af312b8fc112d3e204b36a1d4ca106547e9d232a.png',

  // CDN + 'bg_popup_toast.png',
  'https://cdngarenanow-a.akamaihd.net/webid/FF_hash/luchalibre/images-hash/b2144dff5c536fb6e9348d0f46e80c22ddfdb8d86535303346a39855a290722b.png',

  // CDN + 'popup_button.png',
  'https://cdngarenanow-a.akamaihd.net/webid/FF_hash/luchalibre/images-hash/0e5806f64adad4c30f37446fe72fd80da13cd2ee4572628439a66bd42bcd3c6b.png',

  // CDN + 'no_history.png',
  'https://cdngarenanow-a.akamaihd.net/webid/FF_hash/luchalibre/images-hash/79afb814cabc5b6c211a74edff6fcbeff7f2cb04fe2278caf0738f3af883a307.png',

  // CDN + 'ico_popup_close.png',
  'https://cdngarenanow-a.akamaihd.net/webid/FF_hash/luchalibre/images-hash/efc58f3cdf51ef66798104dcebbd8e167fd2b9fdccb81bb82cfd754da8ab31e8.png',

  // CDN + 'popup_down.png',
  'https://cdngarenanow-a.akamaihd.net/webid/FF_hash/luchalibre/images-hash/47515d2d7b35eea1cdc83d7b1dae8ec8ad0e2ada85eeb511fa4b9c49f14c59bf.png',

  // SOUND
  CDN + 'sounds/add_points.mp3',
  CDN + 'sounds/bgm.mp3',
  CDN + 'sounds/click.mp3',
  CDN + 'sounds/close.mp3',
  CDN + 'sounds/dart.mp3',
  CDN + 'sounds/hit_board.mp3',
  CDN + 'sounds/popup.mp3',
  CDN + 'sounds/popup_big.mp3',
  CDN + 'sounds/popup_normal.mp3',
  CDN + 'sounds/toast.mp3',
];
