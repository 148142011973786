import React, { useEffect } from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { set_token } from 'redux/actions/auth';

// Component
import ErrorBoundary from 'ErrorBoundary';
import ViewportRestrict from 'helper/component/viewport';
import Loader from 'helper/component/loader';
import NotFound from 'helper/component/404';
// import LoadingScreen from 'helper/component/loadingscreen';
import { Header, Modal } from 'components';

import { preload } from 'helper';
import Home from 'pages/Home';

// Pages
// const Home = lazy(() => componentLoader(() => import('pages/Home')));

const Main = () => {
  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state.main);

  useEffect(() => {
    preload();
    dispatch(set_token());
    // eslint-disable-next-line
  }, []);

  return (
    <Router basename={'/'}>
      <div id="Main" className="main-panel">
        <ErrorBoundary>
          <ViewportRestrict display={true} type="portrait" />
          <Loader loader={loader} />
          <Modal />
          <div className="content-container">
            <Header />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route component={NotFound} />
            </Switch>
          </div>
        </ErrorBoundary>
      </div>
    </Router>
  );
};

export default Main;
