import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { translate } from 'redux/actions/main';
import { toggle_modal } from 'redux/actions/modal';

import { ButtonSound } from 'components';

const Header = () => {
  const handleModal = (type) => {
    if (!on_animation) {
      if (type === 'rules') {
        dispatch(toggle_modal('rules', true));
      } else {
        dispatch(toggle_modal('history', true));
      }
    }
  };

  const dispatch = useDispatch();
  const { info, on_animation } = useSelector((state) => state.main);
  const { player } = info;
  const t = (key) => dispatch(translate(key));

  if (!info) {
    return <section className="header"></section>;
  }

  return (
    <section className="header">
      <ButtonSound />
      <div className="title">{t('DE_P1_HOME_EVENT_TITLE')}</div>
      <button className="btn-icon rules" onClick={() => handleModal('rules')} />
      <button className="btn-icon history" onClick={() => handleModal('history')} />
      <div className="user-info">
        <div className="left">
          <div className="diamond" />
        </div>
        <div className="right">
          <div className="diamond">{player.gems}</div>
          <div className="uid">UID: {player.uid}</div>
        </div>
      </div>
    </section>
  );
};

export default Header;
