const main_state = {
  login: false,
  loader: false,
  info: false,
  histories: false,
  on_animation: false,
  shoot_animation: false,
  skip_animation: false,
};

const main = (state = main_state, action) => {
  switch (action.type) {
    case 'PUT_DATA':
      return { ...state, [action.key]: action.data };
    case 'TOGGLE_LOADER':
      return { ...state, loader: action.data };

    default:
      return state;
  }
};

export default main;
