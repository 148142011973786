import React, { useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import stringReplace from 'react-string-replace';

import { useDispatch } from 'react-redux';
import { translate, shoot } from 'redux/actions/main';
import { toggle_modal } from 'redux/actions/modal';

import { ButtonClose } from 'components';

import { ls } from 'helper';
import { audio } from 'helper/audio';

const Confirm = (props) => {
  const [tick, setTick] = useState(false);

  const handleClickButton = () => {
    audio('click').play();
    dispatch(toggle_modal('confirm', false));
    dispatch(shoot(count, discountCount > 0 ? discountedPrice : price));
  };

  const handleTick = () => {
    audio('click').play();
    if (tick) {
      ls.removeItem('dont_remind');
    } else {
      ls.setItem('dont_remind', !tick);
    }
    setTick(!tick);
  };

  const renderConfirmationText = () => {
    let replacedText = stringReplace(t('DE_T1_DIAMOND_GEM_COST'), '{diamond}', (match, i) => (
      <React.Fragment key={i}>
        <div className="icon" />
        {discountCount > 0 ? discountedPrice : price}
      </React.Fragment>
    ));

    replacedText = stringReplace(replacedText, '{num}', (match, i) => count);

    return replacedText;
  };

  const dispatch = useDispatch();
  const { confirm, data_modal } = props.modal;
  const { count, price, discountedPrice, discountCount } = data_modal;
  const t = (key) => dispatch(translate(key));

  useEffect(() => {
    confirm && audio('popup').play();
    setTick(false);
    // eslint-disable-next-line
  }, [confirm]);

  return (
    <Modal
      open={confirm}
      onClose={() => {
        dispatch(toggle_modal('confirm', false));
        ls.removeItem('dont_remind');
      }}
      focusTrapped={false}
      center
      showCloseIcon={false}
      closeOnOverlayClick={false}
      classNames={{
        overlay: 'overlay',
        modal: 'modal confirm',
      }}>
      <ButtonClose
        onClick={() => {
          dispatch(toggle_modal('confirm', false));
          ls.removeItem('dont_remind');
        }}
      />
      <div className="content">
        <div className="text">{renderConfirmationText()}</div>
        <div className="reminder" onClick={() => handleTick()}>
          <div className="tick">{tick ? <div className="icon" /> : ''}</div>
          <span>{t('POPUP_NO_REMIND')}</span>
        </div>
        <button className="btn-modal" onClick={handleClickButton}>
          {t('COMMON_CONFIRM')}
        </button>
      </div>
    </Modal>
  );
};

export default Confirm;
