import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useDispatch } from 'react-redux';
import { translate } from 'redux/actions/main';
import { toggle_modal } from 'redux/actions/modal';

import { PrizeBox, ButtonClose } from 'components';

import { audio } from 'helper/audio';

const Congrat = (props) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const PrevArrow = ({ onClick }) => (
    <div className={`prev-arrow ${currentSlide === 0 ? 'disable' : ''}`} onClick={onClick} />
  );

  const NextArrow = ({ onClick }) => (
    <div
      className={`next-arrow ${currentSlide === prizes.length + helperBox.length - 3 ? 'disable' : ''}`}
      onClick={onClick}
    />
  );

  const RenderBottomText = () => {
    if (isToken) {
      return isMultiple ? t('UNIQUE_BUY_ALREADY_HAVE_PART') : t('UNIQUE_REDEEM_ALREADY_HAVE');
    }

    return t('COMMON_TIPS_ITEM');
  };

  const dispatch = useDispatch();
  const { congrat, data_modal } = props.modal;
  const prizes = data_modal;
  const t = (key) => dispatch(translate(key));

  const isMultiple = prizes.length > 1;
  const isToken = prizes.length > 0 && prizes.find((prize) => prize.is_token);

  const helperBox = prizes.length > 2 ? Array(3 - (prizes.length % 3) === 3 ? 0 : 3 - (prizes.length % 3)) : [];

  const settings = {
    className: 'prize-wrapper',
    infinite: false,
    speed: 500,
    slidesToShow: prizes.length === 2 ? 2 : 3,
    slidesToScroll: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  useEffect(() => {
    if (congrat) {
      audio('popupNormal').play();
      setCurrentSlide(0);
    }

    // eslint-disable-next-line
  }, [congrat]);

  if (!congrat) {
    return null;
  }

  return (
    <Modal
      open={congrat}
      onClose={() => dispatch(toggle_modal('congrat', false))}
      focusTrapped={false}
      center
      showCloseIcon={false}
      closeOnOverlayClick={false}
      classNames={{
        overlay: 'overlay',
        modal: 'modal congrat',
      }}>
      <ButtonClose onClick={() => dispatch(toggle_modal('congrat', false))} />
      <div className="content">
        <div className="text">{t('POPUP_TITLE_CONGRATULATIONS')}</div>
        {isMultiple ? (
          <Slider {...settings}>
            {prizes.map((prize, i) => (
              <div key={i} className="prize">
                <PrizeBox isCongrat={true} prize={prize} tooltipDisabled={true} grandPrize={prize.is_grandprize} />
                <div className="two-lines">{prize.item_name}</div>
              </div>
            ))}
            {helperBox.fill(null).map((item, i) => (
              <div key={`transparant-${i}`} className="prize">
                <PrizeBox tooltipDisabled={true} isTransparant={true} />
              </div>
            ))}
          </Slider>
        ) : (
          <div className="prize-wrapper">
            {prizes.map((prize, i) => (
              <div key={i} className="prize">
                <PrizeBox isCongrat={true} prize={prize} tooltipDisabled={true} grandPrize={prize.is_grandprize} />
                <span>{prize.item_name}</span>
              </div>
            ))}
          </div>
        )}
        <button
          className="btn-modal"
          onClick={() => {
            audio('close').play();
            dispatch(toggle_modal('congrat', false));
          }}>
          {t('COMMON_CONFIRM')}
        </button>
        <div className="owned">
          <RenderBottomText />
        </div>
      </div>
    </Modal>
  );
};

export default Congrat;
