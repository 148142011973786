import { Howl } from 'howler';
import { CDN } from 'helper';

export const audio = (type, option) => {
  const sounds = {
    bgm: {
      mpeg: CDN + 'sounds/bgm.mp3',
      wav: CDN + 'sounds/bgm.wav',
    },
    click: {
      mpeg: CDN + 'sounds/click.mp3',
      wav: CDN + 'sounds/click.wav',
    },
    close: {
      mpeg: CDN + 'sounds/close.mp3',
      wav: CDN + 'sounds/close.wav',
    },
    dart: {
      mpeg: CDN + 'sounds/dart.mp3',
      wav: CDN + 'sounds/dart.wav',
    },
    hitBoard: {
      mpeg: CDN + 'sounds/hit_board.mp3',
      wav: CDN + 'sounds/hit_board.wav',
    },
    addPoints: {
      mpeg: CDN + 'sounds/add_points.mp3',
      wav: CDN + 'sounds/add_points.wav',
    },
    popup: {
      mpeg: CDN + 'sounds/popup.mp3',
      wav: CDN + 'sounds/popup.wav',
    },
    popupNormal: {
      mpeg: CDN + 'sounds/popup_normal.mp3',
      wav: CDN + 'sounds/popup_normal.wav',
    },
    popupBig: {
      mpeg: CDN + 'sounds/popup_big.mp3',
      wav: CDN + 'sounds/popup_big.wav',
    },
    toast: {
      mpeg: CDN + 'sounds/toast.mp3',
      wav: CDN + 'sounds/toast.wav',
    },
  };

  const sound = new Howl({
    src: [sounds[type].mpeg, sounds[type].wav],
    ...option,
  });

  return sound;
};
