import React, { useEffect, useState } from 'react';
import { useCountUp } from 'react-countup';

import { useDispatch, useSelector } from 'react-redux';
import { translate, put_data, claim_milestone } from 'redux/actions/main';

import { audio } from 'helper/audio';

import { PrizeBox } from 'components';

const Milestone = () => {
  const [animateToken, setAnimateToken] = useState(false);
  const [progressBar, setProgressBar] = useState(0);

  const isClaimable = (pointRequired, itemId) => {
    if (player.point >= pointRequired) {
      if (!player.claimed_milestones.includes(itemId)) {
        return true;
      }
    }
    return false;
  };

  const isClaimed = (itemId) => {
    if (player.claimed_milestones.includes(itemId)) {
      return true;
    }
    return false;
  };

  const handleClaim = (itemId, stage) => {
    if (!on_animation) {
      dispatch(claim_milestone(itemId, stage));
    }
  };

  const dispatch = useDispatch();
  const { info, on_animation, shoot_animation } = useSelector((state) => state.main);
  const { milestone, player } = useSelector((state) => state.main.info);
  const t = (key) => dispatch(translate(key));

  const countUpRef = React.useRef(null);
  const { start } = useCountUp({
    ref: countUpRef,
    start: player.point,
    end: player.point + shoot_animation.point,
    delay: 0,
    duration: 0.5,
  });

  useEffect(() => {
    setProgressBar(player.milestone_percentage);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (shoot_animation) {
      setTimeout(() => {
        audio('addPoints', { volume: 0.5 }).play();
        setAnimateToken(true);

        setTimeout(() => {
          start();
          setProgressBar(shoot_animation.milestone_percentages);

          setTimeout(() => {
            setAnimateToken(false);
            dispatch(put_data('shoot_animation', false));
            dispatch(put_data('info', { ...info, player: { ...info.player, point: player.point + shoot_animation.point } }));
          }, 100); // Animate end
        }, 750); // Animate counter
      }, 750); // Animate points
    }
    // eslint-disable-next-line
  }, [shoot_animation]);

  useEffect(() => {
    setProgressBar(player.milestone_percentage);
    // eslint-disable-next-line
  }, [player.milestone_percentage]);

  return (
    <section className="milestone">
      <div className="top">
        <p>{t('DE_P1_HOME_POINTS_GET')}</p>
        {animateToken && <span className="increase">+{shoot_animation.point}</span>}
        <div className="token">
          <div className="icon" />
          <span ref={countUpRef} />
        </div>
      </div>
      <div className="bottom">
        <div className="bar">
          <div className="filled" style={{ height: `${progressBar}%` }} />
          {milestone.map((item, i) => (
            <div key={i} className={`bullet bullet-${i + 1} ${player.point >= item.stage ? 'fill' : ''}`} />
          ))}
        </div>
        <div className="prize-wrapper">
          {[...milestone].reverse().map((item, i) => (
            <React.Fragment key={i}>
              <div className={`prize ${`prize-${i + 1}`}`}>
                <div className="amount">
                  <div className="icon" />
                  <span>{item.stage}</span>
                </div>
                <PrizeBox
                  prize={item}
                  isMilestone={true}
                  claimable={isClaimable(item.stage, item.id)}
                  claimed={isClaimed(item.id)}
                  tooltipContent={item.item_name}
                  tooltipPlacement="right"
                  onClick={() => isClaimable(item.stage, item.id) && handleClaim(item.id, item.stage)}
                />
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Milestone;
