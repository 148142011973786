import React from 'react';

import { Board, Milestone } from 'components';
import Shoot from 'components/Shoot';
import { useSelector } from 'react-redux';

const Home = () => {
  const { info } = useSelector((state) => state.main);

  if (!info) {
    return <div className="home"></div>;
  }

  return (
    <div className="home">
      <Milestone />
      <Board />
      <Shoot />
      <div className="brand" />
    </div>
  );
};

export default Home;
