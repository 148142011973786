import React, { useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import { useDispatch } from 'react-redux';
import { translate } from 'redux/actions/main';
import { toggle_modal } from 'redux/actions/modal';

import { audio } from 'helper/audio';

const Error = (props) => {
  const dispatch = useDispatch();
  const { error, msg } = props.modal;
  const { info } = props.main;
  const t = (key) => dispatch(translate(key));

  const errCode = {
    NOT_LOGIN: 'not_login',
    TOAST_EVENTOVER: 'Event ended',
    TOAST_EVENT_CLOSED_AWHILE: 'The event is closed temporary',
    TOAST_EVENT_END: 'Event ended',
    TOAST_EVENT_NOTOPEN: "Event hasn't started",
    TOAST_LOGIN_FAILED: 'Login failed',
    TOAST_WRONG_REGION: 'This event is not available for your region',
  };

  const isClosable = !errCode[msg.message];

  useEffect(() => {
    if (error) {
      audio('toast').play();

      if (isClosable) {
        const timerFunc = setTimeout(() => {
          dispatch(toggle_modal('error', false));
        }, 2000);

        return () => clearTimeout(timerFunc);
      }
    }

    // eslint-disable-next-line
  }, [error]);

  return (
    <Modal
      open={error}
      onClose={() => dispatch(toggle_modal('error', false))}
      focusTrapped={false}
      center
      showCloseIcon={false}
      closeOnOverlayClick={false}
      classNames={{
        overlay: 'overlay error',
        modal: 'modal toast error',
      }}>
      <div className="content" onClick={() => isClosable && dispatch(toggle_modal('error', false))}>
        <p>{info ? t(msg.message) : errCode[msg.message]}</p>
      </div>
    </Modal>
  );
};

export default Error;
