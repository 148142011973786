import React, { useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { format, fromUnixTime } from 'date-fns';

import { useDispatch } from 'react-redux';
import { translate, get_history } from 'redux/actions/main';
import { toggle_modal } from 'redux/actions/modal';

import { PrizeBox, ButtonClose } from 'components';

import { audio } from 'helper/audio';

const History = (props) => {
  const [showMultiplePrize, setShowMultiplePrize] = useState(false);
  const [hasScrollBar, setHasScrollBar] = useState(false);
  const [isEndScroll, setIsEndScroll] = useState(false);

  const prizeShoot = (prize, i) => {
    const handleClickMore = () => {
      audio('click').play();
      if (showMultiplePrize === i + 1) {
        setShowMultiplePrize(false);
      } else {
        setShowMultiplePrize(i + 1);
      }
      checkScrollBar();
    };

    const isMilestone = prize.type === 'milestone';
    const sortedRewards = [...prize.rewards].sort((a, b) => a.priority - b.priority);
    const isMultiplePrize = sortedRewards.length > 1;
    const isGrandPrize = sortedRewards[0].is_grandprize;

    return (
      <React.Fragment key={i}>
        <div className="list">
          <div className="prize">
            <PrizeBox prize={sortedRewards[0]} tooltipDisabled={true} multiple={isMultiplePrize} grandPrize={isGrandPrize} />
          </div>
          <div className="name">
            {isMultiplePrize ? (
              <div className="more" onClick={handleClickMore}>
                <div>{t('DE_T3_HISTORY_MORE')}</div>
                <div className={`icon ${showMultiplePrize === i + 1 ? 'rotate' : ''}`} />
              </div>
            ) : (
              <span>{sortedRewards[0].item_name}</span>
            )}
          </div>
          <div className="cost">
            <>
              {!isMilestone && <div className="icon" />}
              <span>{prize.discounted_price === 0 ? t('DE_P1_HOME_PRICE_ZERO') : prize.discounted_price}</span>
              {prize.discounted_price !== prize.price && (
                <div className="price_before_discount">
                  <span>{prize.price}</span>
                  <div className="scribble" />
                </div>
              )}
            </>
          </div>
          <div className="date">{format(fromUnixTime(prize.created_at), 'HH:mm, dd/MM/yyyy')}</div>
        </div>
        {showMultiplePrize === i + 1 && (
          <div className="reward-details">
            {sortedRewards.map((reward, idx) => (
              <div key={idx} className="reward">
                <PrizeBox prize={reward} tooltipDisabled={true} grandPrize={reward.is_grandprize} />
                <span>{reward.item_name}</span>
              </div>
            ))}
          </div>
        )}
      </React.Fragment>
    );
  };

  const checkScrollBar = () => {
    setTimeout(() => {
      const ele = document.querySelector('#history');
      const hasScrollableContent = ele.scrollHeight > ele.clientHeight;
      setHasScrollBar(hasScrollableContent);
    }, 250);
  };

  const handleScroll = (e) => {
    const endScroll = e.target.scrollHeight - e.target.scrollTop - 10 < e.target.clientHeight;
    setIsEndScroll(endScroll ? true : false);
  };

  const dispatch = useDispatch();
  const { history } = props.modal;
  const { histories } = props.main;
  const t = (key) => dispatch(translate(key));

  const IsContainHistory = histories.length > 0;

  useEffect(() => {
    if (history) {
      audio('popup').play();
      setIsEndScroll(false);
      setShowMultiplePrize(false);
      dispatch(get_history());

      checkScrollBar();
    }

    // eslint-disable-next-line
  }, [history]);

  return (
    <Modal
      open={history}
      onClose={() => dispatch(toggle_modal('history', false))}
      focusTrapped={false}
      center
      showCloseIcon={false}
      closeOnOverlayClick={false}
      classNames={{
        overlay: 'overlay',
        modal: 'modal history',
      }}>
      <ButtonClose onClick={() => dispatch(toggle_modal('history', false))} />
      <div className="title">{t('COMMON_HISTORY_DRAW')}</div>
      <div id="history" className="content" onScroll={handleScroll}>
        {IsContainHistory ? (
          histories.map((data, i) => prizeShoot(data, i))
        ) : (
          <div className="no-history">
            <div className="icon" />
            <p>{t('COMMON_HISTORY_NORECORDS')}</p>
          </div>
        )}
      </div>
      {hasScrollBar && <div className={`arrow ${isEndScroll ? 'hidden' : ''}`} />}
    </Modal>
  );
};

export default History;
