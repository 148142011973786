import * as main from './main';
import { set_msg, toggle_modal } from './modal';
import { fetchCss, getUrlParam } from 'helper';
import { BrowserInfo } from 'helper/ingame';

export const set_param = (data) => ({
  type: 'SET_PARAM',
  data,
});

export const set_token = () => {
  return (dispatch) => {
    const param = {
      access_token: getUrlParam('access_token') || '',
      account_id: getUrlParam('account_id') || '',
      region: getUrlParam('region') || 'SG',
      lang: getUrlParam('lang') || 'en',
    };

    fetchCss(param.lang, param.region);

    if (param.access_token === '') {
      dispatch(toggle_modal('error', true));
      dispatch(set_msg('', 'NOT_LOGIN'));
    } else {
      dispatch(set_param(param));
      dispatch(main.get_info(true));
      if (BrowserInfo().iPhone || BrowserInfo().iPad || BrowserInfo().ios) {
        document.body.id = 'ios';
      }
      if (BrowserInfo().android) {
        document.body.id = 'android';
      }
      document.body.setAttribute('data-lang', param.lang);
    }
  };
};
