import React from 'react';
import Tippy from '@tippyjs/react';

import { useSelector } from 'react-redux';

import { audio } from 'helper/audio';

const Tooltip = ({ content, disabled, children, placement }) => {
  const { on_animation } = useSelector((state) => state.main);

  return (
    <Tippy
      className="tooltip"
      content={content}
      disabled={disabled || on_animation}
      placement={placement}
      trigger="click"
      onShow={(instance) => {
        audio('click').play();
        setTimeout(() => {
          instance.hide();
        }, 2000);
      }}>
      {children}
    </Tippy>
  );
};

export default Tooltip;
