import React from 'react';
import stringReplace from 'react-string-replace';

import { useDispatch, useSelector } from 'react-redux';
import { shoot, translate } from 'redux/actions/main';
import { set_msg, toggle_modal } from 'redux/actions/modal';

import { ls } from 'helper';
import { audio } from 'helper/audio';

const Shoot = () => {
  const renderButtonShoot = (count) => {
    const handleClickButton = () => {
      audio('click').play();

      if (isFree || price === 0) {
        dispatch(shoot(count, discountCount > 0 ? discountedPrice : price));
      } else {
        if (isDiamondEnough) {
          if (ls.dont_remind) {
            dispatch(shoot(count, discountCount > 0 ? discountedPrice : price));
          } else {
            dispatch(toggle_modal('confirm', true, { count, price, discountedPrice, discountCount }));
          }
        } else {
          dispatch(set_msg('error', 'TOAST_GEM_NOT_ENOUGH'));
          dispatch(toggle_modal('error', true));
        }
      }
    };

    const renderPrice = () => {
      if (isDiscount) {
        return (
          <>
            <div className="price">
              <div className="icon" />
              <span className={price !== 0 && discountPercentage !== 0 && isFree ? 'free' : ''}>
                {isFree ? t('DE_P1_HOME_PRICE_ZERO') : discountedPrice}
              </span>
            </div>
            {price !== 0 && discountPercentage !== 0 && (
              <div className="price_before_discount">
                <span>{price}</span>
                <div className="scribble" />
              </div>
            )}
          </>
        );
      }

      return (
        <div className="price">
          <div className="icon" />
          <span className="no-discount">{price !== 0 ? price : t('DE_P1_HOME_PRICE_ZERO')}</span>
        </div>
      );
    };

    const renderRibbonText = () => {
      let replacedText = stringReplace(t('DE_P1_HOME_DISCOUNT_TIME'), '{remaining num}', (match, i) => discountCount);

      replacedText = stringReplace(replacedText, '{total num}', (match, i) => discountMax);

      return replacedText;
    };

    const price = common_config[count === 1 ? 'one_shoot_cost' : 'shoot_xtimes_cost'];
    const discountCount = player[count === 1 ? 'one_shoot_discount_count' : 'shoot_xtimes_discount_count'];
    const discountMax = common_config[count === 1 ? 'one_shoot_discount_time' : 'shoot_xtimes_discount_time'];
    const discountPercentage = common_config[count === 1 ? 'one_shoot_discount' : 'shoot_xtimes_discount'];
    const discountedPrice = common_config[count === 1 ? 'one_shoot_discounted_cost' : 'shoot_xtimes_discounted_cost'];
    const isDiscount = discountCount > 0;
    const isFree = (isDiscount && discountPercentage === 100) || (isDiscount && discountedPrice === 0);
    const isDiamondEnough = isDiscount ? (isFree ? true : player.gems >= discountedPrice) : player.gems >= price;

    return (
      <button
        className={`btn btn-${count === 1 ? '1' : 'multiple'} ${on_animation ? 'disabled' : ''}`}
        onClick={handleClickButton}>
        <div className="left">
          <div className={`icon ${count === 1 ? 'dart-x1' : 'dart-xmultiple'}`} />
          <span>x{count}</span>
        </div>
        <div className="right">
          <div className="top">{renderPrice()}</div>
          {isDiscount && discountPercentage !== 0 && price !== 0 && (
            <div className="bottom">
              <span>{renderRibbonText()}</span>
            </div>
          )}
        </div>
        {isDiscount && discountPercentage !== 0 && price !== 0 && (
          <div className="ribbon">
            <span>-{discountPercentage}%</span>
          </div>
        )}
      </button>
    );
  };

  const dispatch = useDispatch();
  const { info, on_animation } = useSelector((state) => state.main);
  const { player, common_config, rewards } = info;
  const t = (key) => dispatch(translate(key));
  const grandprize = rewards.find((item) => item.is_grandprize);

  return (
    <section className="shoot">
      <div className="hero-grandprize">
        <img src={grandprize.item_bigsize_img} alt="" />
        <div className="sequence" />
      </div>
      {renderButtonShoot(1)}
      {renderButtonShoot(common_config.shoot_xtimes)}
    </section>
  );
};

export default Shoot;
