import React, { useEffect } from 'react';
import stringReplace from 'react-string-replace';
import { Howler } from 'howler';

import { useDispatch, useSelector } from 'react-redux';
import { put_data, translate } from 'redux/actions/main';

import { Tooltip } from 'components';

import { ls } from 'helper';
import { audio } from 'helper/audio';

const Board = () => {
  const handleTick = () => {
    audio('click').play();
    if (skip_animation) {
      ls.removeItem('skip_animation');
    } else {
      ls.setItem('skip_animation', true);
      dispatch(put_data('on_animation', false));
      dispatch(put_data('shoot_animation', false));
    }
    dispatch(put_data('skip_animation', !skip_animation));
  };

  const renderRibbonText = () => {
    let replacedText = stringReplace(t('DE_P1_HOME_SHOOT_TITLE'), '{darts}', (match, i) => (
      <div key={1} className="icon dart" />
    ));

    replacedText = stringReplace(replacedText, '{point}', (match, i) => <div key={2} className="icon token" />);

    return replacedText;
  };

  const dispatch = useDispatch();
  const { shoot_animation, skip_animation } = useSelector((state) => state.main);
  const { rewards } = useSelector((state) => state.main.info);
  const t = (key) => dispatch(translate(key));

  useEffect(() => {
    if (ls.skip_animation) {
      dispatch(put_data('skip_animation', true));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (shoot_animation) {
      const volume = Howler.volume() * 3;
      audio('dart', { volume }).play();
      setTimeout(() => {
        audio('hitBoard', { volume }).play();
      }, 500);
    }
  }, [shoot_animation]);

  return (
    <section className="board">
      <div className="ribbon">{renderRibbonText()}</div>
      <div className="frame" />
      <div className="prize-wrapper">
        {rewards.map((item, i) => (
          <div key={i} className={`${item.is_grandprize ? '' : 'common'} prize-${item.position}`}>
            {shoot_animation.position === item.position && <div className="highlight" />}
            <div className="img-prize">
              <Tooltip content={item.item_name}>
                <img src={item.item_img} alt="" />
              </Tooltip>
              {item.item_num > 1 && <div className="qty">{item.item_num}</div>}
            </div>
            <div className="token">
              <div className="icon" />
              <span>{item.point}</span>
            </div>
          </div>
        ))}
        {shoot_animation && <div className={`dart dart-${shoot_animation.position}`} />}
      </div>
      <div className="skip_animation" onClick={handleTick}>
        <div className="box-tick">{skip_animation && <div className="icon" />}</div>
        <span>{t('COMMON_SKIP')}</span>
      </div>
    </section>
  );
};

export default Board;
