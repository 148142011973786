import React, { useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import { useDispatch } from 'react-redux';
import { post_policy, translate } from 'redux/actions/main';
import { toggle_modal } from 'redux/actions/modal';

import { audio } from 'helper/audio';

const Policy = (props) => {
  const dispatch = useDispatch();
  const { policy_popup } = props.main.info;
  const { policy } = props.modal;
  const t = (key) => dispatch(translate(key));

  useEffect(() => {
    if (policy) {
      audio('popup').play();
    }

    // eslint-disable-next-line
  }, [policy]);

  return (
    <Modal
      open={policy}
      onClose={() => dispatch(toggle_modal('policy', false))}
      focusTrapped={false}
      center
      showCloseIcon={false}
      closeOnOverlayClick={false}
      classNames={{
        overlay: 'overlay',
        modal: 'modal policy',
      }}>
      <div className="title">{policy_popup?.popup_title}</div>
      <div className="content">
        <div id="policy" className="event-info" dangerouslySetInnerHTML={{ __html: policy_popup?.popup_content }} />
      </div>
      <div className="button-wrapper">
        <button onClick={() => dispatch(post_policy(0))}>{t('COMMON_REJECT')}</button>
        <button onClick={() => dispatch(post_policy(1))}>{t('COMMON_ACCEPT')}</button>
      </div>
    </Modal>
  );
};

export default Policy;
