import React, { useEffect } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

import { useDispatch } from 'react-redux';
import { translate } from 'redux/actions/main';
import { toggle_modal } from 'redux/actions/modal';

import { ButtonClose } from 'components';

import { audio } from 'helper/audio';

const CongratBig = (props) => {
  const removeFirstGrandprize = () => {
    let isFind = false;
    const isMultiGrandPrize = prizes.filter((item) => item.is_grandprize).length > 1;
    const result = [];
    prizes.forEach((item) => {
      if (isMultiGrandPrize) {
        if (item.is_grandprize) {
          isFind && result.push(item);
          isFind = true;
        } else {
          result.push(item);
        }
      } else {
        !item.is_grandprize && result.push(item);
      }
    });

    return result;
  };

  const dispatch = useDispatch();
  const { congrat_big, data_modal } = props.modal;
  const grandprize = data_modal.grandprize;
  const prizes = data_modal.prizes || [];
  const prizesExcludeGrandprize = removeFirstGrandprize();

  const t = (key) => dispatch(translate(key));

  const isMultiple = prizes.length > 1;

  useEffect(() => {
    congrat_big && audio('popupBig').play();
    // eslint-disable-next-line
  }, [congrat_big]);

  return (
    <Modal
      open={congrat_big}
      onClose={() => dispatch(toggle_modal('congrat_big', false))}
      focusTrapped={false}
      center
      showCloseIcon={false}
      closeOnOverlayClick={false}
      classNames={{
        overlay: 'overlay',
        modal: 'modal congrat_big fullscreen',
      }}>
      <ButtonClose
        isFullscreen
        onClick={() => {
          audio('close').play();
          dispatch(toggle_modal('congrat_big', false));
          isMultiple && dispatch(toggle_modal('congrat', true, prizesExcludeGrandprize));
        }}
      />
      <div className={`content ${grandprize?.is_milestone ? 'stage' : ''}`}>
        <h1>{t('POPUP_TITLE_CONGRATULATIONS')}</h1>
        <div className="reward">
          {/* {grandprize?.is_milestone ? <img src={grandprize?.item_bigsize_img} alt="" /> : <div className="img" />} */}
          <img src={grandprize?.item_bigsize_img} alt="" />
        </div>
        {grandprize?.item_num > 1 && <div className="qty">{grandprize?.item_num}</div>}
        <div className="item-name">
          <p>{grandprize?.item_name}</p>
        </div>
        <button
          className="btn-modal"
          onClick={() => {
            audio('close').play();
            dispatch(toggle_modal('congrat_big', false));
            isMultiple && dispatch(toggle_modal('congrat', true, prizesExcludeGrandprize));
          }}>
          {t('COMMON_CONFIRM')}
        </button>
        <div className="owned">{grandprize?.is_token ? t('UNIQUE_REDEEM_ALREADY_HAVE') : t('COMMON_TIPS_ITEM')}</div>
      </div>
    </Modal>
  );
};

export default CongratBig;
