import React, { useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import { useDispatch } from 'react-redux';
import { translate } from 'redux/actions/main';
import { toggle_modal } from 'redux/actions/modal';

import { ButtonClose } from 'components';

import { audio } from 'helper/audio';

const Header = (props) => {
  const [hasScrollBar, setHasScrollBar] = useState(false);
  const [isEndScroll, setIsEndScroll] = useState(false);

  const checkScrollBar = () => {
    setTimeout(() => {
      const ele = document.querySelector('#rules');
      const hasScrollableContent = ele.scrollHeight > ele.clientHeight;
      setHasScrollBar(hasScrollableContent);
    }, 250);
  };

  const handleScroll = (e) => {
    const endScroll = e.target.scrollHeight - e.target.scrollTop - 10 < e.target.clientHeight;
    setIsEndScroll(endScroll ? true : false);
  };

  const dispatch = useDispatch();
  const { rules } = props.modal;
  const { common_config } = props.main.info;
  const t = (key) => dispatch(translate(key));

  useEffect(() => {
    if (rules) {
      audio('popup').play();

      checkScrollBar();
    }

    // eslint-disable-next-line
  }, [rules]);

  return (
    <Modal
      open={rules}
      onClose={() => dispatch(toggle_modal('rules', false))}
      focusTrapped={false}
      center
      showCloseIcon={false}
      closeOnOverlayClick={false}
      classNames={{
        overlay: 'overlay',
        modal: 'modal rules',
      }}>
      <ButtonClose onClick={() => dispatch(toggle_modal('rules', false))} />
      <div className="title">{t('COMMON_RULE')}</div>
      <div className="content">
        <div
          id="rules"
          className="event-info"
          onScroll={handleScroll}
          dangerouslySetInnerHTML={{ __html: common_config.rule }}
        />
      </div>
      {hasScrollBar && <div className={`arrow ${isEndScroll ? 'hidden' : ''}`} />}
    </Modal>
  );
};

export default Header;
