// Game Related
window.promises = window.promises || {};

window.nativePromiseThen = (promiseId, data, error) => {
  if (error) {
    window.promises[promiseId].reject(data);
  } else {
    window.promises[promiseId].resolve(data);
  }
  delete window.promises[promiseId];
};

const generateUUID = () => {
  var d = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    // eslint-disable-next-line
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

export const BrowserInfo = () => {
  const u = navigator.userAgent;
  return {
    //移动终端浏览器版本信息
    trident: u.indexOf('Trident') > -1, //IE内核
    presto: u.indexOf('Presto') > -1, //opera内核
    webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
    // eslint-disable-next-line
    gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
    mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/), //是否为移动终端
    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
    android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器
    iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1, //是否为iPhone或者QQHD浏览器
    iPad: u.indexOf('iPad') > -1, //是否iPad
    // eslint-disable-next-line
    webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
  };
};

export const inGame = () => {
  if (window?.FreeFire || window?.webkit?.messageHandlers?.FreeFire) {
    return true;
  }
  return false;
};

export const gamePostMessage = (msg) => {
  const browser = {
    versions: BrowserInfo(),
    language: window.navigator.language.toLowerCase(),
  };
  if (browser.versions.ios || browser.versions.iPhone || browser.versions.iPad) {
    window?.webkit?.messageHandlers?.FreeFire.postMessage(msg);
  } else if (browser.versions.android) {
    window.FreeFire.postMessage(msg);
  }
};

export const gamePromise = (key, value = '') => {
  const promise = new Promise(function (resolve, reject) {
    const promiseId = generateUUID();
    window.promises[promiseId] = { resolve, reject };
    let msg = {
      PromiseId: promiseId,
    };
    msg[key] = value;
    gamePostMessage(JSON.stringify(msg));
  });

  return promise;
};
export const deepLink = (url, fn) => {
  if (inGame()) {
    gamePostMessage(
      JSON.stringify({
        DeepLinkUrl: url,
      })
    );
  } else {
    window.location.href = url;
  }
  let timer = setTimeout(() => {
    fn();
    clearTimeout(timer);
  }, 3000);
  document.addEventListener(
    'visibilitychange',
    () => {
      document.hidden && clearTimeout(timer);
    },
    { once: true }
  );
};
export const game = {
  inGame,
  run: gamePromise,
  deepLink,
  checkScreenShotOpen: () => {
    if (!inGame()) {
      console.log('must run in game');
      return;
    }
    return gamePromise('CanCaptureScreenshot');
  },
  runScreenShot: () => {
    if (!inGame()) {
      console.log('must run in game');
      return;
    }
    return gamePromise('CaptureAndShareScreenshot');
  },
};
