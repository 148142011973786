import React from 'react';

import { audio } from 'helper/audio';

const ButtonClose = ({ onClick, isFullscreen }) => {
  return (
    <button
      className={`btn-close ${isFullscreen ? 'fullscreen' : ''}`}
      onClick={() => {
        audio('close').play();
        onClick();
      }}
    />
  );
};

export default ButtonClose;
