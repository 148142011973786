import React from 'react';

import { Tooltip } from 'components';

const PrizeBox = (props) => {
  const {
    isMilestone,
    isCongrat,
    prize,
    claimable,
    claimed,
    multiple,
    grandPrize,
    isTransparant,
    onClick,
    tooltipDisabled,
    tooltipPlacement,
  } = props;

  const boxClassNames = ['box'];
  isMilestone && boxClassNames.push('milestone-item');
  isCongrat && boxClassNames.push('congrat');
  claimable && boxClassNames.push('claimable');
  claimed && boxClassNames.push('claimed');
  multiple && boxClassNames.push(grandPrize ? 'multiple-grandprize' : 'multiple');
  grandPrize && boxClassNames.push('grandprize');
  isTransparant && boxClassNames.push('transparant');

  return (
    <Tooltip content={prize?.item_name} disabled={claimable || tooltipDisabled ? true : false} placement={tooltipPlacement}>
      <div className={boxClassNames.join(' ')} onClick={onClick}>
        <img src={prize?.item_img} alt="" />
        {claimed && <div className="tick" />}
        {prize?.item_num > 1 && <div className={`qty ${multiple ? 'multiple' : ''}`}>{prize?.item_num}</div>}
        {claimable && <div className="dot" />}
      </div>
    </Tooltip>
  );
};

export default PrizeBox;
