const modal_state = {
  msg: {
    title: '',
    message: '',
  },
  data_modal: false,
};

// prettier-ignore
const modal = (state = modal_state, action) => {
  switch (action.type) {
    case 'TOGGLE_MODAL':
      if (action.data_modal) {
        return { ...state, [action.modal]: action.toggle, data_modal: action.data_modal };
      } else {
        return { ...state, [action.modal]: action.toggle };
      }
    case 'SET_MSG':
      return {
        ...state,
        msg: {
          title: action.title,
          message: action.msg,
        },
      };
    default:
      return state;
  }
};

export default modal;
