import axios from 'axios';
import { set_msg, toggle_modal } from './modal';
import { initGA, ls } from 'helper';

export const toggle_loader = (data) => ({
  type: 'TOGGLE_LOADER',
  data: data,
});

export const put_data = (key, data) => ({
  type: 'PUT_DATA',
  key,
  data,
});

export const translate = (key) => {
  return (dispatch, getState) => {
    const { transify } = getState().main.info;

    if (transify && transify[key]) {
      return transify[key];
    } else {
      return key;
    }
  };
};

export const get_info = (init = false) => {
  return (dispatch, getState) => {
    dispatch(toggle_loader(true));
    const { param } = getState().auth;

    axios
      .get(`api/info?access_token=${param.access_token}&lang=${param.lang}`)
      .then((resp) => {
        const data = resp.data.data;

        if (resp.data.status === 'error') throw new Error(resp.data.msg);

        if (init) {
          initGA(data.common_config.region, data.player.uid, data.data_log_name);

          if (data.policy_popup && !data.policy_popup.checked) {
            dispatch(toggle_modal('policy', true));
          }
        }

        dispatch(put_data('info', data));
      })
      .catch((err) => {
        dispatch(catch_error(err.message));
      })
      .then(() => {
        dispatch(toggle_loader(false));
      });
  };
};

export const get_history = () => {
  return (dispatch, getState) => {
    dispatch(toggle_loader(true));
    const { param } = getState().auth;

    axios
      .get(`api/history?access_token=${param.access_token}`)
      .then((resp) => {
        if (resp.data.status === 'error') throw new Error(resp.data.msg);
        dispatch(put_data('histories', resp.data.data.history));
      })
      .catch((err) => {
        dispatch(catch_error(err.message));
      })
      .then(() => {
        dispatch(toggle_loader(false));
      });
  };
};

export const shoot = (count, price) => {
  return (dispatch, getState) => {
    dispatch(toggle_loader(true));
    const { info } = getState().main;
    const { param } = getState().auth;

    // DEDUCT GEMS
    dispatch(put_data('info', { ...info, player: { ...info.player, gems: info.player.gems - price } }));

    axios
      .post(`api/shoot?access_token=${param.access_token}`, { shoot_xtimes: count })
      .then((resp) => {
        if (resp.data.status === 'error') throw new Error(resp.data.msg);

        const { rewards } = resp.data.data;

        const sortedRewards = [...rewards].sort((a, b) => a.priority - b.priority);
        const grandprize = sortedRewards.find((reward) => reward.is_grandprize);

        const showModal = () => {
          if (grandprize?.first_grand_prize) {
            dispatch(toggle_modal('congrat_big', true, { grandprize, prizes: sortedRewards }));
          } else {
            dispatch(toggle_modal('congrat', true, sortedRewards));
          }
        };

        if (ls.skip_animation) {
          dispatch(get_info());
          showModal();
        } else {
          const timers = [];

          rewards.forEach((item, i) => {
            dispatch(put_data('on_animation', true));

            timers.push(
              setTimeout(() => {
                if (ls.getItem('skip_animation')) {
                  timers.forEach((timer) => clearTimeout(timer));
                  dispatch(put_data('on_animation', false));
                  dispatch(get_info());
                  showModal();
                } else {
                  dispatch(put_data('shoot_animation', item));

                  if (rewards.length === i + 1) {
                    setTimeout(() => {
                      dispatch(put_data('on_animation', false));
                      dispatch(get_info());
                      showModal();
                    }, 2250);
                  }
                }
              }, 2500 * i)
            );
          });
        }
      })
      .catch((err) => {
        dispatch(catch_error(err.message));
      })
      .then(() => {
        dispatch(toggle_loader(false));
      });
  };
};

export const claim_milestone = (itemId, stage) => {
  const payload = {
    reward_id: itemId,
    stage: stage,
  };

  return (dispatch, getState) => {
    dispatch(toggle_loader(true));
    const { param } = getState().auth;

    axios
      .post(`api/claim?access_token=${param.access_token}`, payload)
      .then((resp) => {
        if (resp.data.status === 'error') throw new Error(resp.data.msg);

        const { reward } = resp.data.data;

        if (reward.is_grandprize) {
          dispatch(toggle_modal('congrat_big', true, { grandprize: { ...reward, is_milestone: true }, prizes: [reward] }));
        } else {
          dispatch(toggle_modal('congrat', true, [reward]));
        }
        dispatch(get_info());
      })
      .catch((err) => {
        dispatch(catch_error(err.message));
      })
      .then(() => {
        dispatch(toggle_loader(false));
      });
  };
};

export const post_policy = (action) => {
  const payload = {
    action: action,
  };

  return (dispatch, getState) => {
    dispatch(toggle_loader(true));
    const { param } = getState().auth;

    axios
      .post(`api/policy_check?access_token=${param.access_token}`, payload)
      .then((resp) => {
        dispatch(get_info());
      })
      .catch((err) => {
        dispatch(catch_error(err.message));
      })
      .then(() => {
        dispatch(toggle_modal('policy', false));
        dispatch(toggle_loader(false));
      });
  };
};

export const catch_error = (err_msg) => {
  return (dispatch) => {
    if (err_msg) {
      dispatch(set_msg('error', err_msg));
    }

    dispatch(toggle_modal('error', true));
    dispatch(toggle_loader(false));
  };
};
