import React, { useEffect, useState } from 'react';
import { Howler } from 'howler';

import { useSelector } from 'react-redux';

import { audio } from 'helper/audio';
import { game } from 'helper/ingame';

const ButtonSound = () => {
  const [inGameVolume, setInGameVolume] = useState(1);
  const [isMute, setIsMute] = useState(false);

  const handleClickButton = () => {
    if (!on_animation) {
      if (inGameVolume === '0') {
        audio('bgm', { loop: true }).play();
        Howler.volume(1);
      } else {
        Howler.volume(inGameVolume);
      }
      Howler.mute(!isMute);
      setIsMute(!isMute);
    }
  };

  const { on_animation } = useSelector((state) => state.main);

  useEffect(() => {
    if (game.inGame()) {
      game.run('Volume', 'music').then((res) => {
        setInGameVolume(res);
        if (res === '0') {
          Howler.mute(true);
          setIsMute(true);
        } else {
          Howler.volume(res);
          audio('bgm', { loop: true }).play();
        }
      });
    } else {
      audio('bgm', { loop: true }).play();
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        Howler.mute(isMute);
      } else {
        Howler.mute(true);
      }
    });
  }, [isMute]);

  return (
    <div className="btn-sound">
      <button className={`ico-sound ${isMute ? 'mute' : 'unmute'}`} onClick={() => handleClickButton()} />
    </div>
  );
};

export default ButtonSound;
